// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-project-project-category-js": () => import("./../../../src/templates/Project/ProjectCategory.js" /* webpackChunkName: "component---src-templates-project-project-category-js" */),
  "component---src-templates-project-project-js": () => import("./../../../src/templates/Project/Project.js" /* webpackChunkName: "component---src-templates-project-project-js" */)
}

