import { css } from 'styled-components'
import { onDesktop } from '@src/utils/responsive'
import get from 'lodash/get'

export const typeBody = css`
    font-family: var(--font-body);
    font-weight: 400;
`

export const typeHeading = css`
    font-family: var(--font-heading);
    font-weight: 400;
`

export const typeItalic = css`
    font-family: var(--font-emphasis);

    font-weight: 100;
    font-style: italic;
    letter-spacing: -0.01em;
`

export const typeHeadingXXL = css`
    ${typeHeading}
    font-size: 5rem;
    line-height: 1.16em;

    .schema-black & {
        letter-spacing: 0em;
    }

    p {
        line-height: 1.1em;
    }

    ${onDesktop`
     font-size: 12rem;
  `}
`

export const typeHeading50 = css`
    ${typeHeading}
    font-size: 2.5rem;
    line-height: 1.16em;

    .schema-black & {
        letter-spacing: 0.01em;
    }

    p {
        line-height: 1.16em;
    }

    ${onDesktop`
    font-size: 5.0rem;
  `}
`

export const typeHeading40 = css`
    ${typeHeading}
    font-size: 2rem;
    line-height: 1.25em;

    ${onDesktop`
    font-size: 4rem;
    line-height: 1.4em;
  `}
`

export const typeHeading28 = css`
    ${typeHeading}

    font-size: 1.4rem;
    line-height: 1.3em;
    font-weight: 500;

    ${onDesktop`
    font-size: 2.8rem;
  `}
`

export const typeHeading24 = css`
    ${typeHeading}

    font-size: 1.2rem;
    line-height: 1.4em;

    ${onDesktop`
    font-size: 2.4rem;
  `}
`

export const typeBody24 = css`
    ${typeBody}

    font-size: 1.2rem;
    line-height: 1.4em;

    ${onDesktop`
    font-size: 2.4rem;
  `}
`

export const typeBody20 = css`
    ${typeBody}

    font-size: 1.2rem;
    line-height: 1.4em;

    ${onDesktop`
    font-size: 2.0rem;
  `}
`

const textSizes = {
    20: typeBody20,
    24: typeBody24,
    28: typeHeading28,
    40: typeHeading40,
    50: typeHeading50,
}

export const getTextSize = (size) => get(textSizes, size, '')
