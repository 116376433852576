import React from 'react'
import styled, { css } from 'styled-components'
import { get } from 'lodash-es'
import { PageTransition } from './PageTransition'

export const EnterFadeSlideOutTransitionSettings = {
    exit: { length: 0.6, delay: 0.6 },
    entry: { length: 0.6, delay: 0 },
}

export const transitionVar = (varName) => (props) =>
    get(props, varName, get(EnterFadeSlideOutTransitionSettings, varName))

export const EnterFadeSlideOutTransition = styled(PageTransition)`
    position: relative;
    z-index: -1 !important;
    &.hidden {
    }

    &.visible {
    }

    &.transition-entering,
    &.transition-exiting,
    &.transition-exited {
        pointer-events: none;
    }

    &.transition-entering {
    }

    &.transition-entering,
    &.transition-entered {
        transition: transform ${transitionVar('entry.length')}s ease
            ${transitionVar('entry.delay')}s;
        transform: translate3d(0%, 0%, 1px);
        opacity: 1 !important;
    }

    &.transition-exiting {
    }

    &.transition-exiting,
    &.transition-exited {
        transition: transform ${transitionVar('exit.length')}s ease ${transitionVar('exit.delay')}s;
        transform: translate3d(0%, 0%, 1px);
        opacity: 1 !important;
    }
`
