import React, { Component, useContext, useState, useEffect } from 'react'
import { css } from 'styled-components'
import { capitalize, debounce } from 'lodash'

export const breakpoints = {
    xs: 0,
    sm: 376,
    md: 640,
    lg: 768,
    xl: 1440,
    xxl: 2560,
}

export const isMobile = () => window.innerWidth <= breakpoints.md

export const isDesktop = () => !isMobile()

export const responsiveSize = (remValue) =>
    isMobile()
        ? (10 / Math.min(breakpoints.sm, window.innerWidth)) * breakpoints.sm * remValue
        : (10 / Math.min(breakpoints.xl, window.innerWidth)) * breakpoints.xl * remValue

export const respondTo = Object.keys(breakpoints).reduce((accumulator, label) => {
    accumulator[label] = (...args) => css`
        @media (min-width: ${breakpoints[label] + 'px'}) {
            ${css(...args)};
        }
    `
    return accumulator
}, {})

export const mobileQuery = `(max-width: ${breakpoints.md - 1 + 'px'})`
export const onMobile = (...args) => css`
    @media (max-width: calc(${breakpoints.md + 'px'} - 1px)) {
        ${css(...args)};
    }
`

export const desktopQuery = `(min-width: ${breakpoints.md + 'px'})`
export const desktopLargeQuery = `(min-width: ${breakpoints.xxl + 'px'})`

export const onDesktop = (...args) => css`
    @media (min-width: ${breakpoints.md + 'px'}) {
        ${css(...args)};
    }
`

export const ResponsiveContext = React.createContext('responsive')

export const useResponsiveContext = () => useContext(ResponsiveContext)

export class ResponsiveProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: null,
            viewportWidth: null,
        }
    }

    resizeHandler() {
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)

        const newViewportSizeIsMobile = isMobile()

        const viewportWidth = window.innerWidth
        const viewportHeight = window.innerHeight
        const aspectRatio = viewportWidth / viewportHeight

        const stateUpdates = {
            isMobile: newViewportSizeIsMobile,
            viewportWidth,
            viewportHeight,
            aspectRatio,
        }

        Object.keys(breakpoints).forEach((breakpoint) => {
            const breakpointValue = breakpoints[breakpoint]
            stateUpdates[`is${capitalize(breakpoint)}Down`] = viewportWidth <= breakpointValue
            stateUpdates[`is${capitalize(breakpoint)}Up`] = viewportWidth > breakpointValue
        })

        this.setState(stateUpdates)
    }

    componentDidMount() {
        this.resizeHandler = debounce(this.resizeHandler.bind(this), 20)

        let intervalCount = 0
        const intervalId = setInterval(() => {
            intervalCount += 1
            this.resizeHandler()

            if (intervalCount > 10) {
                clearInterval(intervalId)
            }
        }, 100)

        window.addEventListener('resize', this.resizeHandler)
        this.resizeHandler()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler)
    }

    render() {
        const { children } = this.props
        const { isMobile, ...state } = this.state

        return (
            <ResponsiveContext.Provider value={{ isMobile, isDesktop: !isMobile, ...state }}>
                {children}
            </ResponsiveContext.Provider>
        )
    }
}

export const ResponsiveConsumer = ResponsiveContext.Consumer

export const createResponsiveComponent =
    (Component) =>
    ({ mobileProps = {}, desktopProps = {}, ...props }) => {
        return (
            <ResponsiveConsumer>
                {({ isMobile, isDesktop }) => (
                    <Component
                        {...props}
                        isMobile={isMobile}
                        isDesktop={!isMobile}
                        {...(isMobile ? mobileProps : desktopProps)}
                    />
                )}
            </ResponsiveConsumer>
        )
    }
