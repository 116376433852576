import { combineReducers } from 'redux'
import global from './slices/global.slice'
import browser from './slices/browser.slice'
import animation from './slices/animation.slice'

export default combineReducers({
    global,
    browser,
    animation,
})
