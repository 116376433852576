import React, { Component, useEffect, useLayoutEffect, useState, useContext, useRef } from 'react'
import { Link } from 'gatsby'

import styled, { css } from 'styled-components'

import classNames from 'classnames'

import { typeHeading50, typeAnchor } from './typography'

import Container from './container'
import theme from '../theme'
import { onMobile, onDesktop, isMobile } from '@src/utils/responsive'

import { throttle, bind, noop, debounce, omit, assign } from 'lodash-es'

import SwapOutLink from './swap-out-link'

import { scrollTo } from '@src/utils/'
import { useLayoutContext } from '../layouts'
import { dispatchEvent } from '@src/utils/dispatch-event'
import { entries } from 'lodash'

const debug = true ? bind(console.log, console, '[Navbar]') : noop

const NavbarContainer = styled(Container)`
    ${({ visible }) =>
        visible
            ? css`
                  opacity: 1;
              `
            : css`
                  opacity: 0;
              `};

    a {
        transition: color 0.15s ease 0.3s;

        color: ${({ schema }) => (schema == 'black' ? 'white' : 'black')};
    }
`

export const NavbarRetainer = styled.div`
    min-height: ${({ theme }) => theme.navbarMobile.height + 'rem'};

    ${onDesktop`
    min-height: ${({ theme }) => theme.navbar.height + 'rem'};
  `}
`

const NavbarStatic = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;

    ${({ visible }) =>
        visible
            ? css`
                  transition: opacity 0.8s ease;
                  opacity: 1;
              `
            : css`
                  opacity: 0;
              `};
`

const navbarDrawerIsStatic = css`
    position: relative;
    transform: translateY(0%);
    background-color: transparent;

    transition: opacity 0.3s ease, background-color 0.15s ease;

    ${({ stickyColor }) =>
        stickyColor &&
        css`
            a {
                color: ${stickyColor};
            }
        `};
`

const navbarDrawerIsFloating = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    ${({ isEnteringFloat, isDrawerVisible }) =>
        isEnteringFloat || !isDrawerVisible
            ? css`
                  transition: opacity 0.3s ease, transform 0s ease, background-color 0s linear;
                  transform: translateY(-100%);
              `
            : css`
                  transform: translateY(${({ visible }) => (visible ? '0%' : '-100%')});
                  transition: opacity 0.3s ease, transform 0.15s ease, background-color 0.15s ease;
              `}

    background-color: ${({ schema, isFloatingAndSliding, isDrawerVisible }) =>
        isFloatingAndSliding || isDrawerVisible
            ? schema == 'black'
                ? 'black'
                : 'white'
            : 'transparent'};
`

const NavbarDrawerContent = styled.div`
    ${({ isFloating }) => (isFloating ? navbarDrawerIsFloating : navbarDrawerIsStatic)};
`

class NavbarDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: true,
            isFloating: false,
            isEnteringFloat: false,
            isExitingFloat: false,
            isFloatingAndSliding: false,
        }

        this.locked = false

        this.saved = {
            lastScrollY: null,
            lastDirection: 1,
            lastDirectionChangeY: null,
        }
    }

    getNavbarScrollState() {
        if (this.saved.lastScrollY == null) {
            this.saved.lastScrollY = window.scrollY
        }

        const direction = this.props.forceDrawerVisible
            ? 1
            : Math.sign(window.scrollY - this.saved.lastScrollY - 0.01)

        if (this.saved.lastDirection != direction) {
            this.saved.lastDirection = direction
            this.saved.lastDirectionChangeY = window.scrollY
        }

        const distanceToChangeY = Math.abs(this.saved.lastDirectionChangeY - window.scrollY)

        const params = {
            lastScrollY: this.saved.lastScrollY,
            scrollY: window.scrollY,
            lastDirection: this.saved.lastDirection,
            lastDirectionChangeY: this.saved.lastDirectionChangeY,
            distanceToDirectionChange: distanceToChangeY,
            direction,
        }

        this.saved.lastScrollY = window.scrollY

        return params
    }

    handleScrollDesktop() {
        const { forceDrawerVisible } = this.props

        const { scrollY, distanceToDirectionChange, direction } = this.getNavbarScrollState()

        let {
            isVisible,
            isFloating,
            isEnteringFloat,
            isExitingFloat,
            isDrawerVisible,
            isFloatingAndSliding,
        } = this.state

        const staticEndY = 0

        const floatStartY = theme.navbar.height * 10 * 2
        const floatSlideStartY = 540
        const floatSlideTolerance = 50

        if (forceDrawerVisible) {
            isVisible = true
            isFloating = true
            isDrawerVisible = true
            isEnteringFloat = false
        } else if (scrollY >= floatStartY) {
            if (!isFloating) {
                isEnteringFloat = true
                isExitingFloat = false
                isDrawerVisible = false
            }
            isFloating = true

            if (scrollY > floatSlideStartY) {
                isEnteringFloat = false
                isFloatingAndSliding = true
                if (distanceToDirectionChange > floatSlideTolerance) {
                    isDrawerVisible = direction == -1
                    isVisible = direction == -1
                }
            } else {
                isFloatingAndSliding = false
            }
        } else {
            if (isFloating) {
                isEnteringFloat = false
                isExitingFloat = true
            }

            if (scrollY <= staticEndY) {
                isExitingFloat = false
            }
            isFloating = false
            isVisible = true
        }

        this.setState({
            ...this.state,
            isVisible,
            isFloating,
            isEnteringFloat,
            isExitingFloat,
            isDrawerVisible,
            isFloatingAndSliding,
        })
    }

    handleScrollMobile() {
        const { forceDrawerVisible } = this.props

        const { scrollY, distanceToDirectionChange, direction } = this.getNavbarScrollState()

        let {
            isVisible,
            isFloating,
            isEnteringFloat,
            isExitingFloat,
            isDrawerVisible,
            isFloatingAndSliding,
        } = this.state

        const floatingStartY = 1

        if (forceDrawerVisible) {
            isVisible = true
            isFloating = true
            isDrawerVisible = true
            isEnteringFloat = false
        } else if (scrollY < floatingStartY) {
            isFloating = false
            isDrawerVisible = true
        } else if (scrollY >= floatingStartY) {
            isFloating = true
            isDrawerVisible = true
        }

        this.setState({
            ...this.state,
            isVisible,
            isFloating,
            isEnteringFloat,
            isExitingFloat,
            isDrawerVisible,
            isFloatingAndSliding,
        })
    }

    handleScroll() {
        if (!this.locked) {
            if (isMobile()) {
                this.handleScrollMobile()
            } else {
                this.handleScrollDesktop()
            }
        }
    }

    goToWork() {
        debug('Go to work')
        const chosenCategory = location.hash.split('/')[1]

        this.locked = true

        this.setState({
            isVisible: true,
            isFloating: true,
            isDrawerVisible: true,
            isEnteringFloat: false,
        })

        setTimeout(() => {
            scrollTo('#work', {
                duration: 0,
                delay: 3,
            })

            if (chosenCategory) {
                dispatchEvent('work:category:select', { category: chosenCategory })
            }
        }, 100)

        setTimeout(() => {
            this.locked = false
        }, 500)
    }

    componentDidMount() {
        document.addEventListener('navbar:show', (event) => {
            debug('Show navbar')
            this.setState({
                isDrawerVisible: true,
            })
        })

        document.body.classList.toggle('navbar-scrolled', true)

        setTimeout(() => {
            document.body.classList.toggle('navbar-scrolled', false)
        }, 1500)

        if (window.location.hash.includes('work')) {
            this.goToWork()
        }

        window.addEventListener('site:pathchange', () => {
            document.body.classList.toggle('navbar-scrolled', true)

            setTimeout(
                () => {
                    document.body.classList.toggle('navbar-scrolled', false)
                },
                window.location.pathname.startsWith('/about') ? 2500 : 1500
            )

            if (window.location.hash.includes('work')) {
                this.goToWork()
            }
        })

        window.addEventListener(
            'scroll',
            debounce(() => {
                this.handleScroll()
            }, 100)
        )

        window.addEventListener(
            'scroll',
            throttle(() => {
                this.handleScroll()
            }, 100)
        )
    }

    render() {
        const { children, forceDrawerVisible, schema, stickyColor } = this.props
        const {
            isVisible,
            isFloating,
            isEnteringFloat,
            isExitingFloat,
            isDrawerVisible,
            isFloatingAndSliding,
        } = this.state

        return (
            <NavbarDrawerContent
                {...this.props}
                {...{
                    visible: isVisible,
                    isFloating:
                        isFloating || (isExitingFloat && isDrawerVisible) || isEnteringFloat,
                    isEnteringFloat: isEnteringFloat,
                    isFloatingAndSliding,
                    isExitingFloat,
                    isDrawerVisible,
                    schema,
                    stickyColor,
                }}
            >
                {children}
            </NavbarDrawerContent>
        )
    }
}

const NavbarInner = styled(Container)`
    display: flex;
    justify-content: space-between;

    min-height: ${({ theme }) => theme.navbarMobile.height + 'rem'};

    ${onDesktop`
    min-height: ${({ theme }) => theme.navbar.height + 'rem'};

  `}
`

const contentMixin = css`
    ${typeHeading50}

    margin: 1.2rem 0;

    ${onDesktop`
    margin: 2.8rem 0 1.8rem 0;
  `}
`

const LeftContent = styled.p`
    ${contentMixin}
`

const RightContent = styled.p`
    ${contentMixin}
`

const NavbarContent = ({ location, schema, onScrollToSection = noop }) => {
    const navbarRef = useRef()
    const { currentSectionId } = useLayoutContext()
    const [nextSection, setNextSection] = useState(currentSectionId)

    const setScrolling = (value) => {
        navbarRef.current.classList.toggle('navbar-scrolling', value)
    }

    const setScrolled = (value) => {
        navbarRef.current.classList.toggle('navbar-scrolled', value)
    }

    const runScrollTo = throttle((e, id, { title = '', state = {} } = {}) => {
        if (document.querySelector(`#${id}`) != null) {
            e.preventDefault()
            e.stopPropagation()
            setScrolling(true)

            scrollTo(`#${id}`, {
                onAnimationStart: () => {
                    debug('Scroll start')

                    if (e.target.href) {
                        history.pushState(state, title, e.target.href)
                    }
                },
                onAnimationEnd: () => {
                    debug('Scroll end')

                    setScrolled(true)
                    setTimeout(() => setScrolling(false), 500)
                    setTimeout(() => {
                        setScrolled(false)
                        setNextSection(null)
                    }, 1500)
                },
            })
            onScrollToSection(id)
        }
    }, 20)

    const path = location.pathname.replace(/\/$/, '') + '/'

    const rightLinkClickHandler = (e) => {
        if (currentSectionId == 'work-section') {
            debug('Right link click overriden')
            setNextSection('reel')
            runScrollTo(e, 'top')
        } else if (path == '/') {
            debug('Right link click overriden')
            setNextSection('work')
            runScrollTo(e, 'work', { title: 'Work' })
        }
    }

    const is = {
        home: path == '/',
        about: path.startsWith('/about'),
        work: currentSectionId == 'work-section',
    }

    const linkFrom = {
        about: {
            text: 'About',
            underlined: is.about,
        },
        paradise: {
            text: 'Paradise',
            underlined: false,
        },
        studio: {
            text: 'Studio',
            underlined: false,
        },
        work: {
            text: 'Work',
            underlined: is.work,
        },
    }

    const linkTo = {
        about: {
            to: '/about/',
            hoverText: 'About',
        },
        studio: {
            to: '/',
            hoverText: 'Studio',
        },
        work: {
            to: '/#work',
            hoverText: 'Work',
        },
        paradise: {
            to: '/',
            hoverText: 'Paradise',
        },
        reel: {
            to: '/',
            hoverText: 'Paradise',
        },
    }

    const leftLinkProps = {
        ...linkFrom.studio,
        ...linkTo.about,
    }

    const rightLinkProps = {
        ...linkFrom.paradise,
        ...linkTo.work,
    }

    if (is.about) {
        assign(leftLinkProps, {
            ...linkFrom.about,
            ...linkTo.about,
            disabled: true,
            onClick: () => {
                window.location.href = '/about/'
            },
        })

        if (rightLinkProps.to == '/#work') {
            debug('Will scroll to #work on page change')
            rightLinkProps.scrollToElementSelector = '#work'
        }
    }

    if (!is.home) {
        if (rightLinkProps.to == '/#work') {
            debug('Will scroll to #work on page change')
            rightLinkProps.scrollToElementSelector = '#work'
        }
    }

    if (is.work) {
        assign(rightLinkProps, {
            ...linkFrom.work,
            ...linkTo.reel,
        })
    }

    if (nextSection == 'work') {
        assign(rightLinkProps, {
            text: 'Work',
            hoverText: 'Work',
            underlined: true,
        })
    } else if (nextSection == 'reel') {
        assign(rightLinkProps, {
            text: 'Paradise',
            hoverText: 'Paradise',
        })
    }

    return (
        <NavbarInner ref={navbarRef}>
            <LeftContent>
                <SwapOutLink {...leftLinkProps} align="left" schema={schema}>
                    {leftLinkProps.text}
                </SwapOutLink>
            </LeftContent>

            <RightContent>
                <SwapOutLink
                    {...rightLinkProps}
                    align="right"
                    schema={schema}
                    onClick={rightLinkClickHandler}
                >
                    {rightLinkProps.text}
                </SwapOutLink>
            </RightContent>
        </NavbarInner>
    )
}

export const Navbar = styled(({ className, schema, retainer = false, location }) => {
    const [forceDrawerVisible, setForceDrawerVisible] = useState(false)

    const [stickyColor, setstickyColor] = useState(schema)
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const eventListeners = {
            'navbar:set': (event) => {
                const { visible } = event.detail

                if (visible !== undefined) {
                    setVisible(visible)
                }
            },

            'navbar:color-change': (event) => {
                const { navbarColor } = event.detail
                setstickyColor(navbarColor)
            },

            /*'carousel:slide-change': (event) => {
                const { navbarColor } = event.detail
                if (location.pathname == '/' || location.pathname == '') {
                    setstickyColor(navbarColor)
                }
            },*/
        }

        entries(eventListeners).forEach(([eventName, listener]) =>
            document.addEventListener(eventName, listener)
        )
        return () =>
            entries(eventListeners).forEach(([eventName, listener]) =>
                document.removeEventListener(eventName, listener)
            )
    }, [])

    useEffect(() => {
        setstickyColor(schema == 'black' ? 'white' : 'black')
    }, [schema, location.pathname])

    return (
        <NavbarContainer
            {...{
                className,
                schema,
                stickyColor,
                visible,
            }}
            role="navigation"
        >
            {retainer ? <NavbarRetainer /> : null}

            <NavbarStatic {...{ visible }}>
                <NavbarDrawer
                    {...{
                        forceDrawerVisible,
                        schema,
                        stickyColor,
                        visible,
                    }}
                >
                    <NavbarContent
                        {...{
                            location,
                            schema,
                            stickyColor,
                        }}
                        onScrollToSection={(section) => {
                            if (section == 'work') {
                                setForceDrawerVisible(true)
                                setTimeout(() => setForceDrawerVisible(false), 1050)
                            }
                        }}
                    />
                </NavbarDrawer>
            </NavbarStatic>
        </NavbarContainer>
    )
})``
