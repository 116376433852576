import React from 'react'
import styled, { css } from 'styled-components'
import { get } from 'lodash-es'
import { PageTransition } from './PageTransition'

export const CrossFadePageTransitionSettings = {
    exit: { length: 0.5, delay: 1 },
    entry: { length: 0.5, delay: 0 },
}

export const transitionVar = (varName) => (props) =>
    get(props, varName, get(CrossFadePageTransitionSettings, varName))

export const CrossFadePageTransition = styled(PageTransition)`
    .page-wrapper > * {
        opacity: 0;
    }

    &.hidden {
    }

    &.visible {
    }

    &.transition-entering {
        z-index: 9999;
    }

    &.transition-entering,
    &.transition-entered {
        z-index: 9999;

        transition: opacity ${transitionVar('entry.length')}s ease ${transitionVar('entry.delay')}s;
        opacity: 1;

        .page-wrapper > * {
            transition: opacity ${transitionVar('entry.length')}s ease
                ${parseFloat(transitionVar('entry.delay')) + 0.6}s;
            opacity: 1;
        }
    }

    &.transition-exiting {
    }

    &.transition-exiting,
    &.transition-exited {
        transition: opacity ${transitionVar('exit.length')}s ease ${transitionVar('exit.delay')}s;
        opacity: 0;

        .page-wrapper > * {
            transition: opacity ${transitionVar('exit.length')}s ease
                ${transitionVar('exit.delay')}s;
            opacity: 1;
        }
    }
`
