import React, { useState, useEffect } from 'react'

import { useInViewRef } from 'rooks'

export const useInViewport = (options = {}) => {
    const [appeared, setAppeared] = useState(false)

    const [elementRef, isVisible] = useInViewRef(undefined, {
        threshold: [0.3, 0.7],
    })

    useEffect(() => {
        if (!appeared && isVisible) {
            setAppeared(true)
        }
    }, [isVisible])

    return [
        elementRef,
        {
            isInViewport: isVisible || false,
            inViewport: isVisible || false,
            appeared,
        },
    ]
}
