import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { animated, useSpring, useSpringRef, useChain, config } from 'react-spring'
import { Heading2, Text } from './Text'
import { Unveil, UnveilGroup } from '../utils/animated'
import Container from './container'
import { desktopQuery, desktopLargeQuery } from '../utils/responsive'
import { dispatchEvent } from '@src/utils/dispatch-event'
import { typeHeading } from './typography'
import { assign, bind, debounce, includes, noop, throttle } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { selectorBrowserState } from '../state/slices/browser.slice'
import { animationSlice } from '../state/slices/animation.slice'

const debug = true ? bind(console.log, console, '[TransitionLayer]') : noop

const timeout = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

const introTextColor = 'white'
const introBackgroundColor = 'black'

const Plane = styled(({ origin = 'left', backgroundColor = 'white', leaveDelay = 0, ...props }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState('idle')

    useEffect(() => {
        const runAnimation = async () => {
            dispatch(animationSlice.actions.setInPageAnimationsEnabled(false))
            await timeout(0.1 * 1000)
            setState('entering')

            await timeout((1 + leaveDelay) * 1000)
            setState('entered')

            await timeout(0.8 * 1000)

            dispatch(animationSlice.actions.setInPageAnimationsEnabled(true))
            await timeout(0.8 * 1000)
            setState('reset')
            await timeout(1.8 * 1000)
            setState('idle')
        }

        runAnimation()
    }, [])

    const planeStyles = useSpring({
        transform: `translate3d(${
            includes(['entered', 'entering'], state) ? 0 : origin == 'left' ? -100 : 100
        }%, 0, 0)`,
        opacity: includes(['entering', 'idle'], state) ? 1 : 0,
        pointerEvents: includes(['entering'], state) ? 'inherit' : 'none',
    })

    return <animated.div {...props} style={planeStyles} />
})`
    position: fixed;
    inset: 0;
    z-index: 999999999;
    background-color: ${({ backgroundColor = 'white' }) => backgroundColor};
`

const ReversePlane = styled(
    ({ origin = 'left', backgroundColor = 'white', leaveDelay = 0, ...props }) => {
        const dispatch = useDispatch()
        const [state, setState] = useState('idle')

        useEffect(() => {
            const runAnimation = async () => {
                dispatch(animationSlice.actions.setInPageAnimationsEnabled(false))
                await timeout(0.1 * 1000)
                setState('entering')

                await timeout((1.4 + leaveDelay) * 1000)
                setState('entered')

                await timeout(0.8 * 1000)
                dispatch(animationSlice.actions.setInPageAnimationsEnabled(true))

                await timeout(0.8 * 1000)
                setState('reset')

                await timeout(1.8 * 1000)
                setState('idle')
            }

            runAnimation()
        }, [])

        const planeStyles = useSpring({
            transform: `translate3d(${
                includes(['entered', 'reset'], state) ? (origin == 'left' ? -100 : 100) : 0
            }%, 0, 0)`,
            opacity: includes(['entering', 'entered'], state) ? 1 : 0,
            pointerEvents: includes(['entering'], state) ? 'inherit' : 'none',
        })

        return <animated.div {...props} style={planeStyles} />
    }
)`
    position: fixed;
    inset: 0;
    z-index: 999999999;
    background-color: ${({ backgroundColor = 'white' }) => backgroundColor};
`

const IntroPlane = styled(animated.div)`
    position: fixed;
    inset: 0;
    z-index: 0;
    background: ${introBackgroundColor};
    color: ${introTextColor};
    display: flex;
    align-items: center;
    justify-items: flex-start;

    opacity: 1;
    pointer-events: none;

    ${({ visible }) =>
        visible
            ? css`
                  transition: opacity 0.3s ease;
                  opacity: 1;
              `
            : css`
                  opacity: 0;
              `}
    pointer-events: ${({ visible }) => (!visible ? 'none' : 'inherit')};

    ${({ zIndex = 0 }) => zIndex && `z-index: ${zIndex};`}
`

const Intro = styled(Container)`
    transition: opacity 0.3s ease;
    opacity: ${({ visible }) => (visible ? 1 : 0)};

    padding-right: 10%;
    @media ${desktopQuery} {
        padding-right: 19%;
    }

    @media ${desktopLargeQuery} {
        padding-right: 14%;
    }
`

const Root = styled(animated.div)``

const Content = styled(animated.div)`
    background: white;
`

const IntroText = styled(Heading2)`
    color: ${introTextColor};

    font-size: 2rem;
    @media ${desktopQuery} {
        font-size: 9rem;
        line-height: 10rem;
    }
`

let lastPath = null

const isHome = (path) => /^\/(\#.*)?$/.test(path)
const isAbout = (path) => (path ? path.startsWith('/about') : false)

export const TransitionLayer = ({ children, ...props }) => {
    const { path } = useSelector(selectorBrowserState)

    const [introSequence, setIntroSequence] = useState(0)
    const [introFinished, setIntroFinished] = useState(false)

    const [contentStyles, contentStylesApi] = useSpring(() => ({
        from: {
            transform: `translate3d(0%, 100vh, 0)`,
        },
        config: {
            ...config.slow,
        },
        onRest: () => {
            setIntroFinished(true)
        },
    }))

    useEffect(() => {
        let playing = false
        const params = new URLSearchParams(window.location.search)
        const reset = () => {
            document.body.style.maxHeight = 'none'
            document.getElementById('___gatsby').style.maxHeight = 'none'

            document.body.style.background = introBackgroundColor
        }

        const runIntro = async () => {
            debug('Run intro')
            playing = true

            dispatchEvent('navbar:color-change', {
                navbarColor: introTextColor,
            })
            await timeout(100)
            dispatchEvent('navbar:color-change', {
                navbarColor: introTextColor,
            })

            if (playing) {
                await timeout(1000)
                if (playing) {
                    setIntroSequence(1)
                    setIntroSequence(2)
                }

                if (playing) {
                    await timeout(3500)
                    if (playing) {
                        setIntroSequence(3)
                        reset()
                        contentStylesApi.start({ to: { transform: `translate3d(0%, 0vh, 0)` } })
                    }
                    if (playing) {
                        await timeout(1500)
                    }
                }
            }
        }

        if (params.has('skip-intro')) {
            debug('Skip intro')
            reset()
            contentStylesApi.start({ from: { transform: `translate3d(0%, 0vh, 0)` } })
            setIntroFinished(true)
        } else if (isHome(location.pathname)) {
            runIntro()
        } else {
            debug('Skip intro')
            reset()
            contentStylesApi.start({ from: { transform: `translate3d(0%, 0vh, 0)` } })
            setIntroFinished(true)
        }

        const scrollListener = (event) => {
            if (event.deltaY && event.deltaY > 0) {
                if (playing) {
                    playing = false
                    console.log('Skip intro', event)
                    window.scrollTo(0, 0)
                    contentStylesApi.start({ to: { transform: `translate3d(0%, 0vh, 0)` } })
                    document.removeEventListener('wheel', scrollListener)
                    document.removeEventListener('touchmove', scrollListener)

                    reset()
                }
            }
        }

        document.addEventListener('touchmove', scrollListener)
        document.addEventListener('wheel', scrollListener)

        return () => {
            document.removeEventListener('wheel', scrollListener)
            document.removeEventListener('touchmove', scrollListener)
        }
    }, [])

    useEffect(() => {
        if (path) {
            debug({ lastPath, path })

            if (!lastPath) {
                window.scrollTo(0, 0)
            }

            lastPath = path
        }
    }, [path])

    let transitionPlane = useMemo(() => {
        if (lastPath != null && lastPath != path) {
            let key = lastPath + '-' + path
            console.log('Create transition plane', key)

            const gray = '#F8F8F8'

            if (path == '/about/') {
                return <Plane key={key} origin="right" backgroundColor="black" />
            } else if (lastPath == '/about/') {
                return <Plane key={key} origin="left" backgroundColor={gray} leaveDelay={0.5} />
            } else if (path == '/') {
                return <Plane key={key} origin="left" backgroundColor={gray} leaveDelay={0.5} />
            } else {
                return <Plane key={key} origin="left" backgroundColor={gray} />
            }
        } else {
            return null
        }
    }, [path])

    return (
        <Root>
            <IntroPlane visible={!introFinished}>
                <Intro visible={introSequence <= 2}>
                    <IntroText>
                        <UnveilGroup visible={introSequence >= 1}>Welcome to Paradise</UnveilGroup>
                        <UnveilGroup visible={introSequence >= 2}>
                            We build brands that are easy to
                        </UnveilGroup>
                        <UnveilGroup visible={introSequence >= 2}>
                            understand and hard to replicate.
                        </UnveilGroup>
                    </IntroText>
                </Intro>
            </IntroPlane>

            <Content style={contentStyles}>{children}</Content>

            {transitionPlane}
        </Root>
    )
}
