import { createSlice } from '@reduxjs/toolkit'

import { assign, get, isEmpty, pickBy, uniqueId, noop } from 'lodash'
import { dispatch } from '../store'

export const globalSlice = createSlice({
    name: 'global',
    initialState: {},
    reducers: {},
})

export const selectorGlobalState = ({ global }) => global

export default globalSlice.reducer
