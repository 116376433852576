import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { lowerCase, get, kebabCase } from 'lodash-es'

import { desktopQuery } from '@src/utils/responsive'

export const em = (value) => `${value.toFixed(2)}em`

export const getMargin = (theme, margin, defaultMargin = 'medium') =>
    get(
        theme.margins,
        (margin || '').toLowerCase(),
        defaultMargin ? theme.margins[defaultMargin] : 0
    )

export const getMobileMargin = (theme, margin, defaultMargin = 'medium') =>
    get(
        theme.mobile.margins,
        (margin || '').toLowerCase(),
        defaultMargin ? theme.mobile.margins[defaultMargin] : 0
    )

export const marginTopMixin = ({ theme, margin, marginTop, marginTopMobile, marginTopDesktop }) =>
    margin || marginTop || marginTopMobile || marginTopDesktop
        ? css`
              margin-top: ${getMobileMargin(theme, marginTopMobile || marginTop)}rem;

              @media ${desktopQuery} {
                  margin-top: ${getMargin(theme, marginTopDesktop || marginTop)}rem;
              }
          `
        : css``

export const marginMixin = () => css`
  ${marginTopMixin}

  ${({ theme, margin, marginBottom, marginBottomMobile, marginBottomDesktop }) =>
      margin || marginBottom || marginBottomMobile || marginBottomDesktop
          ? css`
                margin-bottom: ${getMobileMargin(theme, marginBottomMobile || marginBottom)}rem;

                @media ${desktopQuery} {
                    margin-bottom: ${getMargin(theme, marginBottomDesktop || marginBottom)}rem;
                }
            `
          : css``}

  ${({ theme, margin, marginLeft, marginLeftMobile, marginLeftDesktop }) =>
      margin || marginLeft || marginLeftMobile || marginLeftDesktop
          ? css`
                padding-left: ${getMobileMargin(theme, marginLeftMobile || marginLeft, null)}rem;

                @media ${desktopQuery} {
                    padding-left: ${getMargin(theme, marginLeftDesktop || marginLeft, null)}rem;
                }
            `
          : css``}

  ${({ theme, margin, marginRight, marginRightMobile, marginRightDesktop }) =>
      margin || marginRight || marginRightMobile || marginRightDesktop
          ? css`
                padding-right: ${getMobileMargin(theme, marginRightMobile || marginRight, null)}rem;

                @media ${desktopQuery} {
                    padding-right: ${getMargin(theme, marginRightDesktop || marginRight, null)}rem;
                }
            `
          : css``}
`

export const MARGINS = {
    none: 'none',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xlarge: 'xlarge',
    xxlarge: 'xxlarge',
}

export default {
    navbar: {
        height: 9.6, // rem
    },
    navbarMobile: {
        height: 5.5, // rem
    },

    margins: {
        [MARGINS.none]: 0,
        [MARGINS.small]: 9.0,
        [MARGINS.medium]: 15.0,
        [MARGINS.large]: 22.0,
        [MARGINS.xlarge]: 29.0,
        [MARGINS.xxlarge]: 36.0,
    },

    mobile: {
        margins: {
            [MARGINS.none]: 0,
            [MARGINS.small]: 3, // TBD
            [MARGINS.medium]: 6,
            [MARGINS.large]: 6,
            [MARGINS.xlarge]: 10.0,
            [MARGINS.xxlarge]: 10.0, // TBD
        },
    },

    flexboxgrid: {
        // Defaults
        gridSize: 12, // columns
        gutterWidth: 3, // rem
        outerMargin: 2, // rem
        mediaQuery: 'only screen',
    },
}
