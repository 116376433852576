import { isString, bind, noop, throttle, debounce, isUndefined } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { isMobile } from '@src/utils/responsive'
import { isFunction } from 'lodash'
const debug = true ? bind(console.log, console, '[utils.js]') : noop

export const scrollTo = (
    selector,
    { offset = 0, duration, onAnimationStart = noop, onAnimationEnd = noop, delay = 0 } = {}
) => {
    const Velocity = require('velocity-animate')

    const runScroll = () => {
        const element = document.querySelector(selector)
        debug('Scroll to', selector, element)
        const distance = Math.abs(element.offsetTop - window.scrollY)
        duration = !isUndefined(duration) ? duration : Math.max(300, distance / 10)

        const calculatedOffset = isFunction(offset) ? offset(element) : offset

        if (duration == 0) {
            onAnimationStart()
            window.scrollTo(0, Math.max(0, element.offsetTop + calculatedOffset))
            setTimeout(onAnimationEnd, 50)
        } else {
            const options = {
                duration,
                offset: Math.max(0, element.offsetTop + calculatedOffset),
                easing: 'easeOutCubic',
                begin: onAnimationStart,
                complete: () => setTimeout(onAnimationEnd, 10),
            }
            Velocity(document.body, 'scroll', options)
        }
    }

    if (delay) {
        debug('Delayed scroll to', selector)
        setTimeout(runScroll, delay * 1000)
    } else {
        runScroll()
    }
}

export const useCurrentSection = () => {
    const [currentSectionId, setCurrentSectionId] = useState(null)

    useEffect(() => {
        const detectCurrentSection = throttle(() => {
            const sections = Array.prototype.slice.call(document.getElementsByClassName('section'))
            const scrollY = window.scrollY

            const currentSection = sections.find((section) => {
                const sectionStartY = section.offsetTop
                const sectionEndY = sectionStartY + section.offsetHeight
                return sectionStartY < scrollY && scrollY < sectionEndY
            })

            if (currentSection && currentSection.id != currentSectionId) {
                setCurrentSectionId(currentSection.id)
            } else if (!currentSection && currentSectionId) {
                setCurrentSectionId(null)
            }
        }, 100)

        detectCurrentSection()
        window.addEventListener('scroll', detectCurrentSection)
        return () => window.removeEventListener('scroll', detectCurrentSection)
    }, [currentSectionId, setCurrentSectionId])

    return currentSectionId
}

export const createResponsiveComponent = (Component) => (props) => {
    const [viewportIsMobile, setViewportIsMobile] = useState(true)

    useEffect(() => {
        const handler = throttle(() => {
            if (isMobile() != viewportIsMobile) {
                setViewportIsMobile(isMobile())
            }
        }, 20)

        document.addEventListener('scroll', handler)
        return () => document.removeEventListener('scroll', handler)
    }, [])

    return <Component {...props} isMobile={viewportIsMobile} isDesktop={!viewportIsMobile} />
}
