import React, { useEffect, useState } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { get, omit, bind, noop } from 'lodash-es'
import { DEFAULT_TRANSITION, TRANSITION_SETTINGS } from './Page/PageTransition/transitions'
import { useDispatch } from 'react-redux'
import { animationSlice } from '../state/slices/animation.slice'
import { dispatchEvent } from '../utils/dispatch-event'
import { toggleScroll } from '../utils/scroll-control'
import { scrollTo } from '../utils'

const debug = true ? bind(console.log, console, '[PageTransitionLink]') : noop

const isHome = (path) => /^\/(\#.*)?$/.test(path)

const getPathName = (path) => {
    if (isHome(path || '')) {
        return 'home'
    } else if ((path || '').startsWith('/projects/')) {
        return 'project'
    } else {
        return 'page'
    }
}

export const PageTransitionLink = ({ scrollToElementSelector, ...props }) => {
    const [lastPath, setLastPath] = useState(null)
    const { onClick = noop } = props
    const targetPath = props.to
    const dispatch = useDispatch()

    useEffect(() => {
        function pathChangePolling(event) {
            if (window.location.pathname != lastPath) {
                setLastPath(window.location.pathname)
            }
        }

        let intervalId = setInterval(pathChangePolling, 250)

        return () => {
            clearInterval(intervalId)
        }
    }, [lastPath])

    const defaultDuration = 0.7
    const defaultWaittime = 0.6

    const entryDelay = 0.6

    const animations = {
        home: {
            entry: {
                length: defaultDuration,
                delay: 0,
            },
            exit: {
                length: defaultDuration,
                delay: 0,
            },
        },
        page: {
            entry: {
                length: defaultDuration,
                delay: entryDelay,
            },
            exit: {
                length: defaultDuration,
                delay: 0,
            },
        },
        project: {
            entry: {
                length: defaultDuration,
                delay: entryDelay,
            },
            exit: {
                length: defaultDuration,
                delay: 0,
            },
        },
    }

    const pathName = getPathName(targetPath)
    const lastPathName = getPathName(lastPath)

    const state = {
        enteringPage: pathName,
        exitingPage: lastPathName,
        path: targetPath,
    }

    return (
        <TransitionLink
            preventScrollJump
            {...props}
            exit={{
                ...animations[lastPathName]?.exit,
                state: {
                    ...state,
                    role: 'exiting',
                },
                zIndex: pathName == 'home' ? 3 : undefined,
                trigger: ({ exit, entry, node, ...other }) => {
                    debug('Trigger exit', {
                        exit,
                        node,
                        path: targetPath,
                        lastPath,
                        isHome: isHome(targetPath),
                    })

                    const waittime = defaultWaittime * 1000

                    /*
                    dispatch(animationSlice.actions.setInPageAnimationsEnabled(false))

                    setTimeout(() => {
                        dispatch(animationSlice.actions.setInPageAnimationsEnabled(true))
                    }, exit.length * 1000 + waittime)
                    */

                    dispatchEvent('navbar:set', { visible: false })
                    setTimeout(() => {
                        dispatchEvent('navbar:set', { visible: true })
                    }, waittime)

                    if (true) {
                        toggleScroll(false)

                        setTimeout(() => {
                            try {
                                if (scrollToElementSelector) {
                                    debug('Reset scroll', scrollToElementSelector)

                                    scrollTo(scrollToElementSelector, {
                                        duration: 0,
                                        delay: 1,
                                        onAnimationEnd: () => {
                                            dispatchEvent('navbar:show')
                                        },
                                    })
                                } else {
                                    debug('Reset scroll')
                                    window.scrollTo(0, 0)
                                }
                            } catch (e) {
                                console.error('Error running scroll', e)
                                debug('Reset scroll')
                                window.scrollTo(0, 0)
                            }
                            toggleScroll(true)
                        }, waittime)
                    }
                },
            }}
            entry={{
                ...animations[pathName]?.entry,
                state: {
                    ...state,
                    role: 'entering',
                },
                zIndex: pathName == 'home' ? 0 : 1,
                trigger: ({ exit, entry, node }) => {
                    debug('Trigger entry', { entry, node })
                },
            }}
            onClick={(e) => {
                onClick(e)
            }}
        />
    )
}

export default PageTransitionLink
