import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

let store = null

export const createStore = () => {
    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose

    store = reduxCreateStore(
        rootReducer,
        /* preloadedState, */
        composeEnhancers(applyMiddleware(thunk))
    )

    return store
}

export const dispatch = (...args) => store.dispatch(...args)

export const getStore = () => store

export const getState = () => store.getState()

export default store
