import React from 'react'
import styled, { css } from 'styled-components'
import { get } from 'lodash-es'
import { PageTransition } from './PageTransition'

export const FakeSlideLeftPageTransitionSettings = {
    exit: { length: 0.8, delay: 0.6 },
    entry: { length: 0.8, delay: 0.6 },
}

export const transitionVar = (varName) => (props) =>
    parseFloat(get(FakeSlideLeftPageTransitionSettings, varName, 0))

export const FakeSlideLeftPageTransition = styled(PageTransition)`
    position: relative;
    z-index: 10;

    &:after {
        position: fixed;
        inset: 0px;
        content: '';
        display: block;
        background: red;

        pointer-events: none;
    }

    &.transition-entering {
        &:after {
            transition: transform ${transitionVar('entry.length')}s ease
                    ${transitionVar('entry.delay')}s,
                opacity 0.3s ease ${transitionVar('entry.length') + transitionVar('entry.delay')}s;
            opacity: 1;
            transform: translate3d(0%, 0%, 1px);
        }
        .page-wrapper {
            opacity: 0;
        }
    }

    &.transition-entered {
        &:after {
            opacity: 0;
            transform: translate3d(0%, 0%, 1px);
        }

        .page-wrapper {
            opacity: 1;
        }
    }

    &.transition-exiting {
        &:after {
            transition: transform 0.8s ease 0.3s, opacity 0.3s ease;
            transform: translate3d(0%, 0%, 1px);
            opacity: 1;
        }

        .page-wrapper {
            opacity: 1;
        }
    }

    &.transition-exited {
        &:after {
            transition: transform 0.8s ease 0s;
            transform: translate3d(100%, 0%, 1px);
        }
    }

    &.hidden {
    }

    &.visible {
    }
`
