import React, { useEffect, useState } from 'react'
import { omit, pick, bind, noop } from 'lodash-es'

import { default as GatsbyLink } from 'gatsby-link'
import { PageTransitionLink } from './transition-link'

import styled, { css } from 'styled-components'
import { onDesktop, onMobile } from '@src/utils/responsive'

const debug = false ? bind(console.log, console, '[SwapOutLink]') : noop

const scaleHiddenY = 0.5
const scaleHiddenX = 0.95
const opacityHidden = 0

const transitionDuration = 0.15

const NormalText = styled.span`
    position: relative;

    transition: transform ${transitionDuration}s ease, opacity ${transitionDuration * 0.75}s ease;

    transform-origin: 50% 100%;

    transform: scale(1, 1);
    opacity: 1;
    line-height: 7rem;
`

const HoverText = styled(({ className, style, children }) => (
    <span {...{ className, style }}>{children}</span>
))`
    position: absolute;
    bottom: 0;
    left: ${({ align }) => (align == 'left' ? '0%' : align == 'right' ? '100%' : '50%')};

    transform: translate(
            ${({ align }) => (align == 'left' ? '0%' : align == 'right' ? '-100%' : '-50%')},
            105%
        )
        scale(${scaleHiddenX}, ${scaleHiddenY});

    transform-origin: 50% -0%;

    transition: transform ${transitionDuration}s ease, opacity ${transitionDuration * 1.25}s ease;
    opacity: ${opacityHidden};

    white-space: nowrap;
    line-height: 7rem;
`

const Content = styled.span`
    display: inline-block;
    pointer-events: none;

    position: relative;

    transition: top ${transitionDuration}s ease, transform ${transitionDuration}s ease;

    top: 0%;
    right: 4px;
    transform: translateY(0%);
    line-height: 7rem;
    width: 12rem;

    ${onDesktop`
    width: 22rem;
  `}

    max-width: 100%;

    text-align: ${({ align }) => align};
`

const LinkElement = styled((props) => {
    const { enableTransitions = true } = props
    const LinkImplementation = enableTransitions ? PageTransitionLink : GatsbyLink

    const linkProps = pick(
        props,
        'className',
        'style',
        'onClick',
        'onMouseEnter',
        'to',
        'children',
        'scrollToElementSelector'
    )

    return <LinkImplementation {...linkProps} />
})`
  position: relative;

  overflow: hidden;

  display: inline-block;

  padding: 0.7rem 0.5rem;
  margin: -1rem -0.4rem;

  position: relative;

  line-height: 0.92;

  cursor: default;

  
  
  &.debug:after, &.debug:before {
    position absolute;
    left: 0; right: 8px;
    content: '';
    display: block;
  }

  &.debug:before {
    bottom: 50%;
    height: 1px;
    background-color: red;
  }

  &.debug:after {
    top: 14px;
    bottom: 14px;
    border: 1px solid cyan;
  }

  ${onDesktop`
    
    &.underlined .normal-text:after {
      
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.4rem;
        height: 0.4rem;
        width: 100%;
        background-color: ${({ schema }) => (schema == 'black' ? 'white' : 'black')};
      
    }
  `}

  transition: opacity 0.75s ease;
  opacity: 1;


  ${({ hoverEnabled }) =>
      hoverEnabled
          ? onDesktop`

    &:hover, .navbar-scrolling & {
      text-decoration: none;
      cursor: pointer;

      .content {
        transform: translateY(-100%);
      }  

      .normal-text {
        opacity: ${opacityHidden};
        transform: scale(${scaleHiddenX}, ${scaleHiddenY});
      }

      .hover-text {
        opacity: 1;
        transform: translate(${({ align }) =>
            align == 'left' ? '0%' : align == 'right' ? '-100%' : '-50%'}, 100%) scale(1, 1);
      }
    }
    
    &.underlined:hover, .navbar-scrolling & { 
      .normal-text:after {
        background-color: transparent;
      }
    }

    

    .navbar-scrolled & {
      
    }
    .navbar-scrolling & {
      * {
        transition: none;
      }
    }

    .navbar-scrolling &, .navbar-scrolled & {
      pointer-events: none;
      * {
        transition: none;
      }
    }
    
  `
          : ``}
`

export const SwapOutLink = styled((props) => {
    const {
        className,
        style,
        children,
        underlined = false,
        hoverText,
        hoverTextStyle,
        align = 'center',

        disabled = false,
        to,
        onClick,
        enableTransitions = true,
        schema,
    } = props

    const [hoverEnabled, setHoverEnabled] = useState(false)

    return (
        <LinkElement
            {...props}
            className={className + (underlined ? ' underlined' : '') /*+ ' debug'*/}
            onClick={onClick}
            onMouseEnter={() => setHoverEnabled(true)}
            hoverEnabled={hoverEnabled && !disabled}
            schema={schema}
        >
            <Content className="content" align={align}>
                <NormalText className={'normal-text'}>{children}</NormalText>

                <HoverText className="hover-text" style={hoverTextStyle} align={align}>
                    {hoverText}
                </HoverText>
            </Content>
        </LinkElement>
    )
})`
    &:hover {
        cursor: pointer;
    }

    ${onMobile`
    height: 2rem;
    & > * {
      transform: translateY(-40%);

    }
    
  `}
`

export default SwapOutLink
