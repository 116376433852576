import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useInViewport } from './in-viewport'
import styled, { css } from 'styled-components'
import { has, isString } from 'lodash'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectorAnimationState } from '../state/slices/animation.slice'

export const animated =
    (Component, options = {}) =>
    ({ visible, ...props }) => {
        const [animatedRef, visibilityPayload] = useInViewport(options)

        const [{ appeared, isInViewport }, setVisibilityPayload] = useState(
            visible === undefined ? visibilityPayload : { appeared: visible, isInViewport: false }
        )

        const { inPageAnimationsEnabled } = useSelector(selectorAnimationState)

        useEffect(() => {
            if (inPageAnimationsEnabled) {
                const newPayload =
                    visible === undefined
                        ? visibilityPayload
                        : { appeared: visible, isInViewport: false }

                false &&
                    console.log('VISIBILITY WILL BE UPDATED', {
                        newPayload,
                        appeared,
                        isInViewport,
                        visibilityPayload,
                        visible,
                    })

                setVisibilityPayload(newPayload)
            }
        }, [
            visible,
            visibilityPayload.appeared,
            visibilityPayload.isInViewport,
            inPageAnimationsEnabled,
        ])

        return (
            <Component
                ref={animatedRef}
                {...props}
                className={classNames(
                    props.className,
                    'animated',
                    isInViewport ? 'animated--in-viewport' : '',
                    appeared ? 'animated--appeared' : ''
                )}
            />
        )
    }

export const Unveil = styled(
    ({ className, children, dangerouslySetInnerHTML, delay, ...props }) => (
        <span {...props} className={classNames('unveil', className)}>
            <span {...(dangerouslySetInnerHTML ? { dangerouslySetInnerHTML } : { children })} />
        </span>
    )
)`
    overflow: hidden;
    display: inline-block;

    span {
        display: block;
        transition: transform 0.8s ease ${({ delay = 0.100464 }) => delay}s;
        transform: translate3d(0, 100%, 1px);
    }
    margin-bottom: -0.5rem;

    .animated--appeared & {
        span {
            transform: translate3d(0, 0%, 1px);
        }
    }
`

const stringToUnveilElements = (children) =>
    children
        .replace(/\<\/?(p)\>/g, '')
        .split(' ')
        .map((part, index) => (
            <Fragment key={index}>
                {index != 0 && ' '}

                {part.split(/\<br\s*\/?\>/g).map((innerPart, innerIndex, a) => (
                    <Fragment key={innerIndex}>
                        {innerIndex != 0 && <br />}
                        <Unveil>{innerPart.replace(/\<[^\/]*\/?\>/g)}</Unveil>
                    </Fragment>
                ))}
            </Fragment>
        ))

export const UnveilGroup = animated(
    styled(
        React.forwardRef(({ className, children, visible, ...props }, ref) => {
            const groupedChildren = isString(children)
                ? stringToUnveilElements(children)
                : has(children, 'childMarkdownRemark.html')
                ? stringToUnveilElements(children.childMarkdownRemark.html)
                : children

            return (
                <div {...props} className={classNames('unveil-group', className)} ref={ref}>
                    {groupedChildren}
                </div>
            )
        })
    )``
)
