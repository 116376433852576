import { createSlice } from '@reduxjs/toolkit'

import { assign } from 'lodash'

export const animationSlice = createSlice({
    name: 'animation',
    initialState: {
        inPageAnimationsEnabled: true,
    },
    reducers: {
        setInPageAnimationsEnabled: (state, { payload: inPageAnimationsEnabled = true }) => {
            assign(state, { inPageAnimationsEnabled })
        },
    },
})

export const selectorAnimationState = ({ animation }) => animation

export default animationSlice.reducer
