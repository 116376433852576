import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { omit } from 'lodash-es'

const PageTransitionContent = styled.div``

export const PageTransition = ({ transitionStatus, mount, exit, enter, ...props }) => {
    return (
        <div {...omit(props, 'children', 'transitionData')}>
            <PageTransitionContent>{props.children}</PageTransitionContent>
        </div>
    )
}
