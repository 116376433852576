import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { get, lowerCase, bind, noop } from 'lodash-es'

import { Navbar } from '../components/navbar'

import theme from '../theme'

import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'

import '../theme.css'
import { useCurrentSection } from '@src/utils/'
import { ResponsiveProvider } from '../utils/responsive'

import { TransitionLayer } from '../components/TransitionLayer'
//import { GlobalStyle } from '../theme'

const debug = false ? bind(console.log, console, '[Layout]') : noop

export const LayoutContext = React.createContext()
export const useLayoutContext = () => useContext(LayoutContext)

export default ({ path, location, children, nextEntryUrl, ...props }) => {
    const [currentPath, setCurrentPath] = useState(path)

    useEffect(() => {
        if (path != currentPath) {
            setCurrentPath(path)
            const event = new CustomEvent('site:pathchange', {
                currentPath: path,
                previousPath: currentPath,
            })
            window.dispatchEvent(event)
        }
    }, [path])

    const currentSectionId = useCurrentSection()

    const schema = lowerCase(get(props, 'data.contentfulPage.variant', 'white'))

    const rootPath =
        typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__
            ? (rootPath = __PATH_PREFIX__ + `/`)
            : `/`

    return (
        <ResponsiveProvider>
            <LayoutContext.Provider value={{ currentSectionId }}>
                <ThemeProvider theme={theme}>
                    {false && (
                        <div
                            id="visibility-viewport"
                            style={{
                                position: 'fixed',
                                inset: '20% 0',
                                pointerEvents: 'none',
                                background: 'rgba(255, 0, 0, 0.2)',
                                zIndex: 9999,
                            }}
                        ></div>
                    )}
                    <a id="top" />
                    <Navbar schema={schema} location={location} path={path} />

                    <TransitionLayer path={path}>{children}</TransitionLayer>
                </ThemeProvider>
            </LayoutContext.Provider>
        </ResponsiveProvider>
    )
}
