import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { get, pick, has, isObject, omit } from 'lodash-es'
import { onDesktop } from '@src/utils/responsive'

import {
    typeItalic,
    typeBody20,
    typeBody24,
    typeHeading24,
    typeHeading28,
    typeHeading40,
    typeHeading50,
} from './typography'
import { omitProps } from '@src/utils/omit-props'
import classNames from 'classnames'
import { animated, Unveil, UnveilGroup } from '../utils/animated'

const withProps = (extraProps) => (Component) =>
    React.forwardRef((ownProps, ref) => <Component {...extraProps} {...ownProps} ref={ref} />)

const BaseText = React.forwardRef(({ tag = 'div', children, visible, unveil, ...props }, ref) => {
    const Tag = tag

    const isMarkdownChild = isObject(children) && has(children, 'childMarkdownRemark')

    return isMarkdownChild ? (
        unveil ? (
            <div {...props} ref={ref}>
                <UnveilGroup>{children}</UnveilGroup>
            </div>
        ) : (
            <div
                {...props}
                ref={ref}
                dangerouslySetInnerHTML={{
                    __html: children.childMarkdownRemark.html,
                }}
            />
        )
    ) : (
        <Tag {...props} ref={ref}>
            {unveil ? <UnveilGroup>{children}</UnveilGroup> : children}
        </Tag>
    )
})

export const Text = styled(omitProps('collapseMargin')(BaseText))`
    h1 {
        font-size: 1em;
    }

    h2 {
        font-size: 0.85em;
    }

    h3 {
        font-size: 0.85em;
        opacity: 0.75;
    }

    ${({ collapseMargin }) =>
        collapseMargin
            ? css`
                  & > :first-child {
                      margin-top: 0;
                  }

                  & > :last-child {
                      margin-bottom: 0;
                  }
              `
            : css``}

    i, em {
        ${typeItalic}
    }

    b,
    strong {
        font-weight: 500;
    }

    p a {
        padding-bottom: 0.16em;

        border-bottom: 0.07 em solid var(--black);

        .schema-black & {
            border-bottom: 0.07em solid var(--white);
        }

        &:hover {
            text-decoration: none;
        }

        ${onDesktop`
      &:hover {
        border-color: transparent;
      }
    `}
    }
`

export const Heading2 = styled(withProps({ tag: 'h2' })(Text))`
    ${typeHeading50}
    margin-top: 5.0rem;
    margin-bottom: 6.5rem;

    ${onDesktop`
    margin-top: 12.7rem
    margin-bottom: 6.5rem;
  `}
`

export const Heading3 = styled(withProps({ tag: 'h3' })(Text))`
    ${typeHeading28}
    margin-top: 1.3em;
    margin-bottom: 1.3em;
`

export const Heading4 = styled(withProps({ tag: 'h3' })(Text))`
    ${typeHeading24}
    margin-top: 0em;
    margin-bottom: 1.3em;
`

/* Title depends on heading/body context */
export const Title = styled(withProps({ tag: 'span' })(BaseText))`
    display: block;
    line-height: 1.28em;
    margin-bottom: 0.2em;
`

/* Subtitle depends on heading/body context */
export const Subtitle = styled(withProps({ tag: 'span' })(BaseText))`
  ${typeItalic}
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  margin-top: 0;
  margin-bottom: 0;  

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`

export const Body1 = styled(Text)`
    ${typeBody24}
    margin-top: 0;
    margin-bottom: 1.3em;
`

export const Body2 = styled(Text)`
    ${typeHeading40}
    margin-top: 0;
    margin-bottom: 1.4em;
`

export const BodySmall = styled(Text)`
    ${typeBody20}
    margin-top: 0;
    margin-bottom: 1.2em;
`
