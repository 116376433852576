import React from 'react'
import styled from 'styled-components'

import { onDesktop } from '@src/utils/responsive'

export const Container = styled.div`
    box-sizing: border-box;

    max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '192.0rem')};
    padding: 0 ${({ fullwidth }) => (fullwidth ? '0px' : '2.1rem')};
    width: 100%;

    ${onDesktop`
    padding: 0 ${({ fullwidth }) => (fullwidth ? '0px' : '6.25rem')};
    width: 100%;
  `}

    margin: 0 auto;
`

export default Container
