import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { assign } from 'lodash'

export const browserSlice = createSlice({
    name: 'browser',
    initialState: {
        path: null,
    },
    reducers: {
        routeUpdate: (state, { payload: { newPath, oldPath } }) => {
            assign(state, { path: newPath })
        },
    },
})

export const selectorBrowserState = ({ browser }) => browser

export default browserSlice.reducer
