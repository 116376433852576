import { wrapWithReduxProvider } from './src/ReduxProvider'
import { browserSlice } from './src/state/slices/browser.slice'
import { dispatch } from './src/state/store'

import { normalizePathname } from './src/utils/url'

export const wrapRootElement = wrapWithReduxProvider

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
    const newPath = normalizePathname(location.pathname)
    const oldPath = prevLocation ? normalizePathname(prevLocation.pathname) : null

    dispatch(browserSlice.actions.routeUpdate({ newPath, oldPath }))
}
